import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/routes';
import { runMiddleware } from '@/router/middleware';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  return runMiddleware({ to, from, router });
});

router.afterEach(() => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  window.parentIFrame?.size();
});

// Redirect on error caused by new deployment
router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
