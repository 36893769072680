import { myyKy, myyUploadKy } from '@/api/myy';
import type { UploadRawFile } from 'element-plus';
import { FileUpload } from '@/models/FileUpload';
import { Entry } from '@/models/Entry';

export const entries = {
  async view(id: string) {
    //
  },

  async viewByAlias(alias: string) {
    return Entry.fromApi(await myyKy.get(`entries/by-alias/${alias}`).json<Entry>());
  },

  async store(data: any) {
    return Entry.fromApi(await myyKy.post('entries', { json: data }).json<Entry>());
  },

  async uploadFile(upload: UploadRawFile) {
    const formData = new FormData();
    formData.append('activityId', import.meta.env.VITE_MYY_ACTIVITY_ID);
    formData.append('receipt', upload, upload.name);

    return FileUpload.fromApi(await myyUploadKy.post('entries/receipt', { body: formData }).json<FileUpload>());
  },
};
