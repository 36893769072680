<script setup lang="ts">
import FooterLink from '@/components/PageLink.vue';
import { usePage } from '@/composables/usePage';
import type { OtherPage } from '@/types/Page';

const otherPages = usePage<OtherPage[]>('other');
</script>

<template>
  <footer class="footer">
    <div class="container text-center">
      <div class="flex flex-wrap justify-center items-center gap-x-5">
        <FooterLink page-name="entry" class="footer-link" />
        <FooterLink page-name="terms" class="footer-link" />
        <FooterLink page-name="privacy" class="footer-link" />
        <FooterLink page-name="premiseLocator" class="footer-link" />
        <FooterLink page-name="refer" class="footer-link" />
        <FooterLink page-name="contact" class="footer-link" />

        <span v-for="(page, idx) in otherPages" :key="idx" class="footer-link">
          <RouterLink :to="{ path: `/${page.slug}` }">{{ page.linkLabel }}</RouterLink>
        </span>
      </div>

      <!--            <li v-if="activity.activity_details.privacy_policy">-->
      <!--              <a target="_blank" :href="activity.activity_details.privacy_policy">Privacy Policy</a>-->
      <!--            </li>-->

      <!--            <li v-if="activity.activity_details.site_copy.disclaimer">-->
      <!--              <NuxtLink :to="{ path: '/collection', query: routerQuery }" :target="target">Collection Notice</NuxtLink>-->
      <!--            </li>-->

      <!--            <li v-if="activity.activity_details.contact_us_url"><ContactLink /></li>-->

      <!--            <li v-if="activity.venues.length > 0"><NuxtLink :to="{ path: '/venues' }">Store Locator</NuxtLink></li>-->

      <!--            <li-->
      <!--              v-if="additionalPages"-->
      <!--              v-for="(page, idx) in additionalPages"-->
      <!--              :key="idx"-->
      <!--              :class="{ hidden: !page.show_in_footer }"-->
      <!--            >-->
      <!--              <NuxtLink :to="{ path: `/extra/${page.slug}` }" :target="target">{{ page.title }}</NuxtLink>-->
      <!--            </li>-->

      <!--            <li v-if="activity.canShowResults()">-->
      <!--              <NuxtLink :to="{ path: 'winners', query: routerQuery }">Winners</NuxtLink>-->
      <!--            </li>-->
      <!--          <div-->
      <!--            v-if="activity.activity_details.site_copy.footer_disclaimer"-->
      <!--            class="my-2"-->
      <!--            style="font-size: 11px"-->
      <!--            v-html="activity.activity_details.site_copy.footer_disclaimer"-->
      <!--          />-->
    </div>
  </footer>
</template>

<style lang="scss">
.footer {
  // TODO: use colours from activity.frontend.colors.footer
  @apply px-2 py-4 text-sm;
  background: var(--myy-color-footer-background);

  img {
    max-height: 40px;
  }
}

.grecaptcha-badge {
  display: none;
}

.footer-link {
  @apply block px-2 py-1;

  a {
    @apply no-underline hover:underline;
    color: var(--myy-color-footer-text);
  }
}
</style>
