<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useThemeStore } from '@/stores/theme';
import { ref, watch } from 'vue';
import { useActivity } from '@/composables/useActivity';
import { useHead } from '@unhead/vue';
import { ucfirst } from '@/helpers/stringHelpers';
import type { Activity, FontDefinition } from '@/models/activity/Activity';
import type { FileUpload } from '@/models/FileUpload';

const { theme } = storeToRefs(useThemeStore());
const activity = useActivity();

watch(
  theme,
  (themeVal) => {
    const properties = {
      '--myy-color-primary': themeVal.primary,
      '--myy-color-primary-light': themeVal.primaryLight,
      '--myy-color-primary-dark': themeVal.primaryDark,
      '--myy-color-primary-darker': themeVal.primaryDarker,
      '--myy-color-text-on-primary': themeVal.textOnPrimary,
      '--myy-color-secondary': themeVal.secondary,
      '--myy-color-page-text': themeVal.pageText,
      '--myy-color-page-background': themeVal.pageBackground,
      '--myy-color-form-input': activity.frontend.colors.form.input,
      // TODO: rename all instances in database of form color 'errors' to 'error' then remove this
      '--myy-color-form-error':
        activity.frontend.colors.form.error ?? activity.frontend.colors.form.errors ?? 'var(--el-color-danger)',
      '--myy-color-form-required-indicator':
        activity.frontend.colors.form.requiredIndicator ?? 'var(--el-color-danger)',
      '--myy-color-footer-background': activity.frontend.colors.footer.background,
      '--myy-color-footer-text': activity.frontend.colors.footer.text,
      // '--myy-font-headings-scale': activity.frontend.fonts.headings?.scale,
    };

    Object.entries(properties).forEach(([variable, value]) => {
      document.documentElement.style.setProperty(variable, value ?? null);
    });
  },
  { immediate: true }
);

// Fonts
type FontsType = Activity['frontend']['fonts'];
const fontCss = ref('');

const weights = ['light', 'regular', 'bold', 'black'] as const;
const weightToCss: Record<(typeof weights)[number], string> = {
  light: 'light',
  regular: 'normal',
  bold: 'bold',
  black: 'bolder',
};

setFonts();

function setFonts() {
  let val = '';

  for (const font of Object.keys(activity.frontend.fonts)) {
    for (const weight of weights) {
      const definition = activity.frontend.fonts[font as keyof FontsType];
      const file = definition?.[weight as keyof FontDefinition] as FileUpload | undefined;
      if (!file) continue;

      val += `@font-face {
        font-family: 'Custom_${ucfirst(font)}';
        font-style: normal;
        font-weight: ${weightToCss[weight]};
        font-display: auto;
        src: url(${file.url});
        size-adjust: ${definition!.scale * 100}%;
      }`;
    }
  }

  fontCss.value = val;
}

useHead({
  style: [
    {
      innerHTML: fontCss,
    },
  ],
});
</script>

<template>
  <div class="theme-provider relative">
    <div class="absolute top-0 left-0 w-full h-full bg-page-background -z-50" />
    <slot />
  </div>
</template>

<!--suppress CssUnresolvedCustomProperty -->
<style lang="scss">
.theme-provider {
  --el-color-primary: var(--myy-color-primary);
  --el-text-color-regular: var(--myy-color-page-text);

  .el-input__inner,
  .el-select__placeholder,
  .el-textarea__inner {
    --el-input-text-color: var(--myy-color-form-input);
  }

  .el-button.el-button--primary {
    --el-button-text-color: var(--myy-color-text-on-primary);
  }

  .el-button,
  .el-button--primary {
    --el-button-hover-text-color: var(--myy-color-text-on-primary);
    --el-button-hover-bg-color: var(--myy-color-primary-dark);
    --el-button-hover-border-color: var(--myy-color-primary-dark);

    --el-button-active-bg-color: var(--myy-color-primary-darker);
    --el-button-active-border-color: var(--myy-color-primary-darker);
  }

  .el-button--primary.is-link {
    --el-button-text-color: var(--myy-color-primary);
  }

  .el-dialog {
    --el-dialog-bg-color: var(--myy-color-page-background);
  }

  .el-checkbox {
    --el-checkbox-checked-icon-color: var(--myy-color-text-on-primary);
  }

  .el-form-item.is-required:not(.is-no-asterisk).asterisk-left
    > .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk).asterisk-left > .el-form-item__label:before {
    color: var(--myy-color-form-required-indicator);
  }
}

:root {
  --el-color-primary: var(--myy-color-primary);
}
</style>
