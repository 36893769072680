import { TimeoutError } from 'ky';
import { toast } from '@/helpers/toast';

class ErrorHandler {
  handle(error: unknown) {
    if (error instanceof TimeoutError) return toast.error('Request timed out. Please try again or contact us.');

    console.error(error);
  }
}

export const errorHandler = new ErrorHandler();
